export const FETCH_URL = process.env.REACT_APP_API_URL;

export const THEME = {
  DARK: "dark",
  LIGHT: "light"
};

export const COOKIES = {
  THEME: "theme"
};
